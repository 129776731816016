const mount = (Splide) => {


  let timeRot;
  let timeRead;
  let dfltTime = 10;

  if(sessionStorage.hasOwnProperty("rotationTime")){
      timeRot = sessionStorage.getItem("rotationTime");
  }else{
      timeRot = dfltTime;
  }

  if(timeRot == ""){
      timeRot = dfltTime;
  }

  if($("section.media-carousel>div.splide-big").length>0){
  
    timeRead = parseInt($("section.media-carousel>div.splide-big").attr("aria-time"));

        
      if(timeRead == 0){
          timeRead = timeRot;
      }

      if(timeRead < 0){
          timeRot = timeRead * (-1);
      }

      timeRot = timeRead * 1000;

      console.log("pp-crsl RT: " + timeRot);

  }


  const carousels = document.querySelectorAll('.splide')
  if (carousels.length === 0) return

  return carousels.forEach((carousel) => {
    const splide = new Splide(carousel, {
      autoplay: true,
      type: 'loop',
      arrows: false,
      speed: 400,
      interval: timeRot,
      pauseOnHover: false,
      resetProgress: true,
      pauseOnFocus: false
    })

    splide.mount()
    
  })
}

export default { mount }
