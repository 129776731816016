const toggleFinder = () => {
  const launchers = document.querySelectorAll('.navigation__mobile-header__controls__finder-launcher')
  const finder = document.querySelector('.navigation__mobile-header__finder')

  if (launchers.length < 1 || !finder) return

  const input = finder.querySelector('.navigation__mobile-header__finder__input')

  const clickOut = (event) => {
    if (
      launchers[0] !== event.target &&
      !launchers[0].contains(event.target) &&
      finder !== event.target &&
      !finder.contains(event.target)) {
      launchers.forEach((launcher) => launcher.classList.remove('is-open'))
      finder.classList.remove('is-open')
    }
  }

  const toggle = () => {
    if (finder.classList.contains('is-open')) {
      launchers.forEach((launcher) => launcher.classList.remove('is-open'))
      finder.classList.remove('is-open')
    } else {
      launchers.forEach((launcher) => launcher.classList.add('is-open'))
      finder.classList.add('is-open')
      setTimeout(() => {
        input.focus()
      }, 500)
    }
  }

  document.addEventListener('click', (event) => clickOut(event))

  launchers.forEach((launcher) => {
    launcher.addEventListener('click', () => toggle())
  })
  return launchers.forEach((launcher) => {
    launcher.removeEventListener('click', () => toggle())
  })
}

const disableScroll = () => {
  const topScroll = window.pageYOffset || document.documentElement.scrollTop
  window.onscroll = () => window.scrollTo(0, topScroll)
}

const enableScroll = () => window.onscroll = function () { }

const toggleOpen = () => {
  const button = document.querySelector('.navigation__mobile-header__controls__toggle')

  if (!button) return
  const menus = document.querySelectorAll('.navigation__menu')
  const navigation = document.querySelector('.navigation')
  const menuMobileLanguages = document.querySelector('.menuLanguageMobile')

  const toggle = () => {
    if (navigation.classList.contains('is-open')) {
      navigation.classList.remove('is-open')
      navigation.classList.remove('is-menu-open')
      menuMoobileLanguages.classList.remove('d-block')
      menus.forEach((menu) => menu.classList.remove('is-open'))
      document.body.classList.remove('is-lock')
      enableScroll()
    } else {
      navigation.classList.add('is-open')
      document.body.classList.add('is-lock')
      disableScroll()
    }
  }

  button.addEventListener('click', () => toggle())
  return button.removeEventListener('click', () => toggle())
}

export default { toggleFinder, toggleOpen }